// Customizable Area Start
import React from "react";

import {
    Container,
    Box,
    Button,
    Typography,
    InputAdornment,
    IconButton,
    Checkbox,
    InputBase,
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOffOutlined";
import Visibility from "@material-ui/icons/VisibilityOutlined";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EmailAccountRegistrationController, {
    Props,
} from "./EmailAccountRegistrationController";
import { featureImg, imgAccess, imgBackground, imgDecline, imgLogo } from "./assets";
import TermsConditions from "../../termsconditions/src/TermsConditions.web";
import CloseIcon from '@material-ui/icons/Close';
const styles = {
    buttonOK: {
        backgroundColor: "#004E9E",
        color: "#FFF",
        paddingRight: 30,
        paddingLeft: 30,
        fontWeight: 700
    },
    successText: {
        fontWeight: 600,
        fontSize: 18,
        lineHeight: "32px",
        color: "#0F172A",
    },
    icon: {
        width: "12px",
        height: "12px",
        marginRight: "6px",
    },
    iconClose: {
        position: "absolute", 
        right: 10, 
        top: 10,
        cursor: "pointer",
        color: "#334155",
    }
}

const NormalText = styled(Typography)({
    fontSize: "14px",
    color: "#0F172A",
    lineHeight: "20px",
    marginLeft: 6,
    fontFamily: "Montserrat, sans-serif",
    "& .highlight": {
        color: "#1C3485",
        marginLeft: 0,
        cursor: "pointer",
        fontWeight: 700,
        fontSize: 16
    },
    "&.error": {
        color: "#DC2626",
        fontSize: "12px",
        lineHeight: "18px",
        marginBottom: "16px",
    }
}) as typeof Typography

const ContainerBox = styled(Container)({
    width: "100%",
    padding: "0",
});

const MainWrapper = styled(Box)({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 960px)": {
        flexDirection: "column",
        justifyContent: "flex-start",
    },
});

const TitleText = styled(Typography)({
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 700,
});

const LogoBox = styled(Box)({
    padding: "5px 40px",
    display: "flex",
    justifyContent: "center"
});

const LabelInput = styled(Typography)({
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    marginBottom: "4px",
    color: "#334155",
});

const StyleInput = styled(InputBase)({
    width: "100%",
    height: "56px",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    padding: "0 8px",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    color: "#0F172A",
    marginBottom: "16px",
    "&:before": {
        borderBottom: "none",
        outline: "none",
    },
    "&:focus, &:focus-within": {
        borderColor: "#6200EA",
        boxShadow: "0 0 0 3px #E1CCFF",
    },
    '& input[type="password"]': {
        fontSize: "16px",
        "&::placeholder": {
            fontSize: "16px",
            color: "#94A3B8",
        },
    },
    '& input[type="text"]': {
        "&::placeholder": {
            fontSize: "16px",
            color: "#94A3B8",
        },
    },
});

const CheckboxContainer = styled(Box)({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "8px",
});

const CheckboxInput = styled(Checkbox)({
    padding: 0,
    color: "#64748B",
    width: "20px",
    height: "20px",
    borderRadius: "6px",
    svg: {
        width: "20px",
        height: "20px",
        borderRadius: "6px",
    },
});

const ButtonSignUp = styled(Button)({
    width: "100%",
    height: "56px",
    borderRadius: "6px",
    background: "linear-gradient(90deg, rgba(118,123,215,1) 0%, rgba(17,31,80,1) 100%)",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    color: "#ffffff",
    marginTop: "23px",
    textTransform: "none",
    "&:disabled": {
        background: "#A9A9A9",
        color: "#ffffff",
        cursor: "not-allowed",
    },
});

const ImageComponent = styled(Box)({
    width: "100%",
    height: "100%",
    objectFit: "cover",
    backgroundImage: `url(${imgBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
})
const BottomContainer = styled(Box)({
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: 20,
    fontSize: 16,
    lineHeight: "24px",
});

const ContainerBlock = styled(Box)({
    width: "50%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width: 960px)": {
        width: "100%",
    },
});

const ContentBlock = styled(Box)({
    marginTop: "40px",

    "@media (max-width: 960px)": {
        width: "100%",
    },
});

const PasswordValidationContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    lineHeight: "18px",
    marginBottom: "8px",
})

const FormBox = styled(Box)({
    width: "50%",
    marginTop: 50,
    marginBottom: 30,
    "@media (max-width: 768px)": {
        width: "80%",
        marginTop: 30,
    },
})
const config = require("./config")
const newTheme = createTheme({
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: "Montserrat, sans-serif"
            },
            body1: {
                fontFamily: "Montserrat, sans-serif"
            }
        },
        MuiInputBase: {
            input: {
                fontFamily: "Montserrat, sans-serif !important"
            }
        },
        MuiButton: {
            root: {
                fontFamily: "Montserrat, sans-serif !important"
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: "8px 8px 32px 8px"
            }
        },
        MuiDialogContent: {
            root: {
                position: 'relative',
                paddingTop: 20, 
                paddingBottom: 30
            }
        }
    }
});
export default class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
    }
    getImage = (type: boolean) => {
        const image = type ? imgAccess : imgDecline;
        return image;
    };

    getStyle = (errorType: boolean) => {
        return {
            border: errorType ? "1px solid #F87171" : "",
            marginBottom: errorType ? "0" : "",
        };
    };

    getVisibilityImg = (show: boolean) => {
        return show ? <Visibility /> : <VisibilityOff />;
    };

    errorToast = () => {
        return this.state.showErrorToast &&
            <Box sx={{ width: "100%" }} style={{ backgroundColor: "#FEE2E2", marginTop: 10, position: "relative", borderRadius: 8, marginBottom: '24px' }}>
                <Box style={{ width: 5, height: "100%", background: "#DC2626", position: "absolute", borderRadius: "8px 0 0 8px" }}></Box>
                <Typography style={{ color: "#DC2626", fontSize: 12, marginTop: 5, padding: "12px 16px" }}>
                    {this.state.errorMsg}
                </Typography>
            </Box>
    }
    dialogConfirm = () => {
        return <Dialog fullWidth open={this.state.isSignupSuccess} onClose={this.handleButtonConfirm}>
            <DialogContent>
                <CloseIcon onClick={this.handleButtonConfirm} data-test-id={"btn-ok-signup"} style={styles.iconClose as React.CSSProperties} />
                <Box style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: 20 }}>
                    <CheckCircleOutlineIcon style={{color: "#34D399", height: 50, width: 50}} />
                    <Typography align="center" style={{ ...styles.successText, marginTop: 10 }}>
                        {config.successMessage}</Typography>
                </Box>
            </DialogContent>
        </Dialog>
    }
    render() {
        return (
            // Required for all blocks
            <ThemeProvider theme={newTheme}>
                <ContainerBox maxWidth={false}>
                    <MainWrapper>
                        <ContainerBlock>
                            <LogoBox>
                                <img
                                    alt="logo"
                                    src={imgLogo}
                                    style={{ width: "140px" }}
                                />
                            </LogoBox>
                            <FormBox>
                                <TitleText>{config.signUp}</TitleText>
                                <ContentBlock>
                                    {this.errorToast()}
                                    <LabelInput>{config.labelFullName}</LabelInput>
                                    <StyleInput
                                        data-test-id="txtInputFullName"
                                        fullWidth={true}
                                        value={this.state.fullName}
                                        onChange={(e) =>
                                            this.onChangeFullName(e.target.value)
                                        }
                                        placeholder={config.labelFullName}
                                    />
                                    <LabelInput>{this.labelEmail}</LabelInput>
                                    <StyleInput
                                        data-test-id="txtInputEmail"
                                        fullWidth={true}
                                        value={this.state.email}
                                        onChange={(e) =>
                                            this.onChangeEmail(e.target.value)
                                        }
                                        placeholder={this.labelEmail}
                                        style={this.getStyle(this.state.invalidEmail)}
                                    />
                                    {this.state.invalidEmail && (
                                            <NormalText className="error">{config.labelInvalidEmail}</NormalText>
                                    )}
                                    <LabelInput>{config.labelTypePassword}</LabelInput>
                                    <StyleInput
                                        data-test-id="txtInputPassword"
                                        type={this.state.enablePasswordField ? "password" : "text"}
                                        placeholder={"Password"}
                                        fullWidth={true}
                                        value={this.state.password}
                                        onChange={(e) =>
                                            this.onChangePassword(e.target.value)
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    data-test-id={"btn-show-hide-password"}
                                                    onClick={this.onShowHidePassword}
                                                    edge="end"
                                                >
                                                    {this.getVisibilityImg(
                                                        this.state.enablePasswordField
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        style={this.getStyle(this.state.invalidPassword)}
                                    />
                                    {this.state.invalidPassword && (
                                        <NormalText className="error">{config.labelPasswordsNotMatch}</NormalText>
                                    )}
                                    <LabelInput>{config.labelConfirmPassword}</LabelInput>
                                    <StyleInput
                                        data-test-id="txtInputConfirmPassword"
                                        type={this.state.enableConfirmPasswordField ? "password" : "text"}
                                        placeholder={"Password"}
                                        fullWidth={true}
                                        value={this.state.reTypePassword}
                                        onChange={(e) =>
                                            this.onChangeConfirmPassword(
                                                e.target.value
                                            )
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.onShowHideConfirmPassword}
                                                    edge="end"
                                                >
                                                    {this.getVisibilityImg(
                                                        this.state.enableConfirmPasswordField
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        style={this.getStyle(this.state.invalidReTypePassword)}
                                    />
                                    {this.state.invalidReTypePassword && (
                                            <NormalText className="error">{config.labelPasswordsNotMatch}</NormalText>
                                    )}
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        {this.state.password && (
                                            <>
                                                <PasswordValidationContainer>
                                                    <img
                                                        style={styles.icon}
                                                        src={this.getImage(
                                                            !this.state.invalidCapitalLetter
                                                        )}
                                                        alt="icon"
                                                    />
                                                    <NormalText>{config.capitalLetter}</NormalText>
                                                </PasswordValidationContainer>
                                                <PasswordValidationContainer>
                                                    <img
                                                        style={styles.icon}
                                                        src={this.getImage(
                                                            !this.state.invalidLowercaseLetter
                                                        )}
                                                        alt="icon"
                                                    />
                                                    <NormalText>{config.lowercaseLetter}</NormalText>
                                                </PasswordValidationContainer>
                                                <PasswordValidationContainer>
                                                    <img
                                                        style={styles.icon}
                                                        src={this.getImage(!this.state.invalidNumber)}
                                                        alt="icon"
                                                    />
                                                    <NormalText>{config.oneNumber}</NormalText>
                                                </PasswordValidationContainer>
                                                <PasswordValidationContainer>
                                                    <img
                                                        style={styles.icon}
                                                        src={this.getImage(!this.state.invalidLength)}
                                                        alt="icon"
                                                    />
                                                    <NormalText>{config.minimumChar}</NormalText>
                                                </PasswordValidationContainer>
                                                <PasswordValidationContainer>
                                                    <img
                                                        style={styles.icon}
                                                        src={this.getImage(!this.state.invalidSym)}
                                                        alt="icon"
                                                    />
                                                    <NormalText>{config.oneSym}</NormalText>
                                                </PasswordValidationContainer>
                                            </>
                                        )}
                                        {this.state.invalidEmail && (
                                            <PasswordValidationContainer>
                                                <img
                                                    style={styles.icon}
                                                    src={imgDecline}
                                                    alt="icon"
                                                />
                                                <NormalText>{config.invalidEmailAddres}</NormalText>
                                            </PasswordValidationContainer>
                                        )}
                                    </Box>
                                    <CheckboxContainer>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                marginTop: 20,
                                            }}
                                        >
                                            <CheckboxInput
                                                data-test-id={"btnChecked"}
                                                color="primary"
                                                onClick={() =>
                                                    this.setAgreeTermAndCondition()
                                                }
                                                checked={this.state.btnChecked}
                                            />
                                            <NormalText component={"span"}>
                                                {config.agreeText}
                                                <NormalText
                                                    className="highlight"
                                                    component={"span"}
                                                    data-test-id="btn-term-and-condition"
                                                    onClick={this.goToTermsAndCondition}
                                                >
                                                    {config.termAndCondition}
                                                </NormalText>
                                            </NormalText>
                                        </Box>
                                    </CheckboxContainer>
                                    <CheckboxContainer>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <CheckboxInput
                                                data-test-id={"btnCheckedPolicy"}
                                                color="primary"
                                                onClick={() =>
                                                    this.setAgreePolicy()
                                                }
                                                checked={this.state.btnCheckedPolicy}
                                            />
                                            <NormalText component={"span"}>
                                                {config.agreeText}
                                                <NormalText
                                                    className="highlight"
                                                    component={"span"}
                                                    data-test-id="btn-policy"
                                                    onClick={this.goToPrivacyPolicy}
                                                >
                                                    {config.policyAndPrivacy}
                                                </NormalText>
                                            </NormalText>
                                        </Box>
                                    </CheckboxContainer>
                                    <ButtonSignUp
                                        data-test-id={"btnSignUp"}
                                        onClick={this.createAccount}
                                        disabled={!this.state.btnChecked || !this.state.btnCheckedPolicy}
                                    >
                                        {config.signUp}
                                    </ButtonSignUp>
                                    <BottomContainer>
                                        <NormalText component={"span"}>
                                            {config.haveAccount}
                                            <NormalText
                                                component={"span"}
                                                data-test-id={"btn-Sign-In"}
                                                className="highlight"
                                                onClick={() => this.goToLogin()}
                                            >
                                                {config.logIn}
                                            </NormalText>
                                        </NormalText>
                                    </BottomContainer>
                                </ContentBlock>
                            </FormBox>
                        </ContainerBlock>
                        <Grid item md={6} sm={12} style={{ display: "flex", height: "inherit" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    width: "100%",
                                    height: "auto",
                                    minHeight: "100vh"
                                }}
                            >
                                <ImageComponent
                                >
                                    <img src={featureImg} style={{ maxWidth: "80%" }} />
                                </ImageComponent>
                            </Box>
                        </Grid>
                    </MainWrapper>
                </ContainerBox>
                {this.dialogConfirm()}
                <TermsConditions
                    id="terms-and-condition"
                    navigation={this.props.navigation}
                    isOpen={this.state.openTermsAndConditions}
                    type="termsConds"
                    onClose={this.goToTermsAndCondition} />
                <TermsConditions
                    id="privacyPolicy"
                    navigation={this.props.navigation}
                    isOpen={this.state.openPrivacyPolicy}
                    type="privacyPolicy"
                    onClose={this.goToPrivacyPolicy} />
            </ThemeProvider>
        );
    }
}
// Customizable Area End