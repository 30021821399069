// Customizable Area Start
import React from "react";
import { Box, ThemeProvider, createTheme, Container, styled, Typography, Grid, CircularProgress, Button, Dialog, DialogContent } from "@material-ui/core";
import Header from "../../navigationmenu/src/Header.web";
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const theme = createTheme({
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: "'Montserrat', sans-serif"
            },
            body1: {
                fontFamily: "'Montserrat', sans-serif"
            }
        },
        MuiInputBase: {
            input: {
                fontFamily: "'Montserrat', sans-serif !important"
            }
        },
    }
})
import AuditInfoController, {
    Props
} from "./AuditInfoController";
import { configJSON } from "./TaskController";

// Customizable Area End

export default class AuditInfo extends AuditInfoController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    InfoItem = (title: string, content: string) => {
        return <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={6}>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <TypographyContent>{title}</TypographyContent>
                    <TypographyContent>:</TypographyContent>
                </Box>
            </Grid>
            <Grid item xs={1}>

            </Grid>
            <Grid item xs={5}>
                <TypographyContent>{content}</TypographyContent>
            </Grid>
        </Grid>
    }

    ImageGroup = (itemImage: {picture_id: string, url: string} ) => {
        return <CustomImg key={itemImage.picture_id}>
            <img src={itemImage.url} alt="" style={styles.image as React.CSSProperties} />
        </CustomImg>
    }

    showButton = () => {
        const role = this.state.userRole.role.toLowerCase()
        const { status } = this.state.auditInfo
        let buttonContent1 = null
        let buttonContent2 = null
        let isFirstButton = false
        let isSecondButton = false
        const inProgress = 'under_review'
        const completed = 'completed'

        if (role === 'all access user' || role === 'team leader' || role === 'account manager') {
            if (status === inProgress) {
                isFirstButton = true;
                buttonContent1 = configJSON.reviewAndEdit;
                isSecondButton = true;
                buttonContent2 = configJSON.completeAudit
            }
        }
        if (role === 'auditor') {
            if (status === inProgress) {
                isFirstButton = true
                buttonContent1 = configJSON.review
            }
        }

        if (status === completed) {
            isFirstButton = true
            buttonContent1 = configJSON.viewReport
        }

        return <Box style={{ ...styles.flexBox }}>
            {isFirstButton && 
            <CustomButton data-test-id="btn-goto-form" onClick={this.gotoForm}>
                <TypographyContent>
                    {buttonContent1}
                </TypographyContent>
            </CustomButton>}
            {isSecondButton &&
                <CustomButton data-test-id="btn-completeAudit" onClick={this.handleCompleteAudit}>
                    <TypographyContent>
                        {buttonContent2}
                    </TypographyContent>
                </CustomButton>}
        </Box>

    }

    DialogSuccess = () => {
        return <Dialog fullWidth open={this.state.isOpenDialogSuccess} onClose={this.onCloseDialog}>
            <DialogContent dividers>
                <CloseIcon data-test-id="btn-close-dialog" onClick={this.onCloseDialog} style={styles.iconClose as React.CSSProperties} />
                <Box style={styles.dialogContent as React.CSSProperties}>
                    <CheckCircleOutlineIcon style={{ color: "#34D399", height: 50, width: 50 }} />
                    <Typography align="center" style={{ ...styles.successText }}>
                        {configJSON.submitAuditSuccessMsg}</Typography>
                </Box>
            </DialogContent>
        </Dialog>
    }

    CheckFoundAudit = () => {
        return this.state.auditNotFound ?
            <TypographyContent style={{ margin: '30px auto' }}>{configJSON.notFound}</TypographyContent> :
            this.CheckPermissionUi()
    }

    CheckPermissionUi = () => {
        const { group } = this.state.userRole
        const role  = this.state.userRole.role?.toLocaleLowerCase()

        return (group === 'regular' || (group === 'client' && this.state.auditInfo.status !== 'completed')) ? 
            <TypographyContent style={{margin: '30px auto'}}>{configJSON.noPermision}</TypographyContent> :
            <ContentBox>
                <TitleGroup>
                    <Title className="title">{configJSON.auditInfo}: #{this.state.auditInfo.audit_number}</Title>
                    <GroupTab>
                        <TabButton data-test-id={"section-audit-info"}
                            onClick={() => this.onChangeTab('audit-info')}
                            className={this.isActiveTab('audit-info')}>
                            <Typography className="section">{configJSON.auditInfo}</Typography>
                        </TabButton>
                        {this.state.userRole.group === 'evermore' &&
                            <TabButton data-test-id={"section-status-tracker"}
                                onClick={() => this.onChangeTab('status-tracker')}
                                className={this.isActiveTab('status-tracker')}>
                                <Typography className="section">{configJSON.statusTracker}</Typography>
                            </TabButton>}
                        <TabButton data-test-id={"section-cap"}
                            onClick={() => this.onChangeTab('cap')}
                            className={this.isActiveTab('cap')}>
                            <Typography className="section">{configJSON.CAP}</Typography>
                        </TabButton>
                    </GroupTab>
                </TitleGroup>
                {this.state.isLoading && <CircularProgress size={20} style={{ marginLeft: 10 }} />}
                {!this.state.isLoading && <Grid container>
                    <Grid item lg={4} md={4} sm={5} xs={12}>
                        {this.InfoItem(configJSON.auditId, this.state.auditInfo.audit_number)}
                        {this.InfoItem(configJSON.accountForm, this.state.auditInfo.form_name)}
                        {this.InfoItem(configJSON.accountName, this.state.auditInfo.account_name)}
                        {this.InfoItem(configJSON.locationName, this.state.auditInfo.location_name)}
                        {this.InfoItem(configJSON.assignTo, this.state.auditInfo.assigned_to)}
                        {this.InfoItem(configJSON.dateCreated, this.state.auditInfo.date_created)}
                        {this.InfoItem(configJSON.address, this.state.auditInfo.address)}
                        {this.InfoItem(configJSON.inTime, this.state.auditInfo.in_time)}
                        {this.InfoItem(configJSON.outTime, this.state.auditInfo.out_time)}
                        {this.InfoItem(configJSON.picName, this.state.auditInfo.pic_name)}
                        {this.InfoItem(configJSON.pointGiven, this.state.auditInfo.points_given)}
                        {this.InfoItem(configJSON.pointApplicable, this.state.auditInfo.points_applicable)}
                        
                    </Grid>
                    <Grid item className="space" lg={2} md={1} sm={1}>

                    </Grid>
                    <Grid className="building-picture" item lg={6} md={7} sm={6} xs={12}>
                            <TypographyContent>
                                {configJSON.buildingPictures}
                            </TypographyContent>
                            <ImageContainer>
                                {this.state.auditInfo.building_pictures.length > 0
                                    ? this.state.auditInfo.building_pictures.map((item: any) => this.ImageGroup(item))
                                    : <TypographyContent>{configJSON.noImage}</TypographyContent>
                                }
                            </ImageContainer>
                            {this.showButton()}
                    </Grid>
                    {
                            (role === 'auditor' && this.state.auditInfo.status === 'assigned') &&
                            <Box className="building-picture">
                                <CustomButton data-test-id="btn-goto-conduct" style={{ marginTop: 20 }} onClick={this.gotoConductAudit}>
                                    <TypographyContent>{configJSON.conductAudit}</TypographyContent>
                                </CustomButton>
                            </Box>

                        }
                </Grid>}
            </ContentBox>

    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            //Merge Engine DefaultContainer
            <Header navigation={this.props.navigation} >
                <ThemeProvider theme={theme}>
                    <CustomContainer maxWidth={false}>
                        {
                            this.state.isRoleLoading ?
                                <CircularProgress style={{ margin: 30 }} /> :
                                this.CheckFoundAudit()
                        }
                        {this.DialogSuccess()}
                    </CustomContainer>
                </ThemeProvider>
            </Header>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const styles = {
    image: {
        width: 121,
        height: 121,
        objectFit: 'cover'
    },
    iconClose: {
        position: 'absolute',
        background: '#FFFFFF',
        padding: 3,
        borderRadius: 100,
        right: 10,
        top: 10,
        border: '1px solid black',
    },
    dialogContent: {
        marginTop: 20,
        marginBottom: 30,
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center',
    },
    flexBox: {
        display: 'flex',
        gap: 20
    },
    successText: {
        color: "#0F172A",
        marginTop: 10,
        fontSize: 24,
        fontWeight: 700,
        lineHeight: "32px",
    },
}

const ImageContainer = styled(Box)({
    display: 'flex',
    overflowX: 'auto',
    padding: "10px 10px 10px 0px",
    gap: 20,
    marginBottom: 20,
    boxSizing: 'border-box',
})

const CustomButton = styled(Button)({
    borderRadius: 8,
    padding: "10px 16px",
    background: "linear-gradient(90deg, rgba(118,123,215,1) 0%, rgba(17,31,80,1) 100%)",

    "&:disabled": {
        cursor: "not-allowed",
        background: "#A9A9A9 !important",
        color: "#FFFFFF",
    },
    "& p": {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 700,
        color: "#FFFFFF",
        lineHeight: "24px",
        fontSize: 16,
    },
    "@media (max-width: 599px)": {
        alignSelf: 'center',
        "& p": {
            fontSize: 14
        }
    }
})
const TitleGroup = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    gap: 20,
    flexDirection: 'column',
})
const Title = styled(Typography)({
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
    color: '#0F172A',
    textTransform: 'capitalize'
})

const CustomImg = styled(Box)({
    width: 168,
    height: 160,
    background: '#FFFFFF',
    boxShadow: "0px 4px 12px 0px #00000033",
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    minWidth: 168
})

const ContentBox = styled(Box)({
    width: "100%",
    display: "flex",
    gap: 20,
    position: 'relative',
    borderRadius: 8,
    flexDirection: "column",
    margin: "30px",
    padding: 30,
    border: '1px solid #CBD5E1',
    minHeight: 'calc(100vh - 205px)',
    height: 'fit-content',
    backgroundColor: '#FFFFFF',
    overflow: 'auto',

    "@media (max-width: 599px)": {
        padding: 20,
        margin: 20,
        "& .space": {
            display: 'none'
        },
        '& .building-picture': {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            margin: '0 auto'
        }
    }
})
const CustomContainer = styled(Container)({
    minHeight: "calc(100vh - 80px)",
    height: "auto",
    position: 'relative',
    padding: 0,
    overflowX: 'hidden',
    display: 'flex'
})
const GroupTab = styled(Box)({
    boxShadow: "0px 4px 4px 0px #00000040",
    width: "fit-content",
    maxWidth: '100%',
    gap: 10,
    borderRadius: 50,
    padding: 5,
    display: 'flex',
    marginBottom: 20,
    "@media (max-width: 599px)": {
        flexWrap: 'wrap',
        boxShadow: 'none'
    }
})


const TypographyContent = styled(Typography)({
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    "@media (max-width: 599px)": {
        fontSize: 14

    }
})

const TabButton = styled(Box)({
    border: "1px solid black",
    padding: "10px 12px",
    color: "#647488",
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '& .section': {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '24px',
        textTransform: 'capitalize'
    },
    "&.active": {
        backgroundColor: "#A0A2FF",
        color: "#152764",
        border: "1px solid #A0A2FF"
    },
    "@media (max-width: 599px)": {
        '& .section': {
            fontSize: 14,
        }
    }
})
// Customizable Area End
