// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");
export interface Props {
    id: string
    navigation: any;
}

interface S {
    oldPassword: string
    visibleOldPassword: boolean
    invalidOldPassword: boolean
    newPassword: string
    retypePassword: string
    invalidPassword: boolean
    invalidCapitalLetter: boolean,
    invalidLowercaseLetter: boolean,
    invalidNumber: boolean,
    invalidLength: boolean,
    invalidSymbol: boolean,
    visibleNewPassword: boolean,
    visibleConfirmPassword: boolean,
    isOpenDialog: boolean
    changePasswordError: string
    userRole: string
}

interface SS {
    navigation: any;
}

export default class ChangePasswordController extends BlockComponent<
    Props,
    S,
    SS
> {
    changePasswordCallId: string = ""
    getUserRoleId: string = ""
    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);



        this.state = {
            oldPassword: '',
            visibleOldPassword: false,
            invalidOldPassword: false,
            invalidPassword: false,
            invalidCapitalLetter: false,
            invalidLowercaseLetter: false,
            invalidNumber: false,
            invalidLength: false,
            invalidSymbol: false,
            newPassword: "", 
            retypePassword: "",
            visibleNewPassword: false,
            visibleConfirmPassword: false,
            isOpenDialog: false,
            changePasswordError: '',
            userRole: "regular"
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getUserRole()
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
              );
        
              let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
              ); 
              if(apiCallId === this.changePasswordCallId) {
                if (responseJson.message) {
                    this.setState({
                        isOpenDialog: true,
                        oldPassword: '',
                        newPassword: '',
                        retypePassword: ''
                    })
                } else if (responseJson.errors) {
                    this.handleError(responseJson.errors)
                }
            }   
            this.handleUserRoleResponse(apiCallId, responseJson)
            
        }
    }

    handleUserRoleResponse = (apiCallId: string, responseJson: any) => {
        if (apiCallId === this.getUserRoleId) {
            if (responseJson.meta) {
                const evermoreGroup = ['all access user', 'account manager', 'team leader', 'auditor']
                const clientUserGroup = ['super user', 'regional manager', 'area manager', 'general manager']
                const role = responseJson.meta.role?.toLowerCase()
                let group = 'regular'
                if (evermoreGroup.includes(role)) group = 'evermore'
                if (clientUserGroup.includes(role)) group = "client"
                this.setState({
                    userRole: group
                })
            }
        }
    }

    handleError = (errors: Array<object | string>) => {
        const listErr: {[key: string] : string} = {};
        errors.forEach((error: Object) => {
            listErr[Object.keys(error)[0]] = Object.values(error)[0]
        });
        if(listErr['token']) this.gotoLogin()
        else {
            this.setState({changePasswordError: errors[0] as string, isOpenDialog: true})
        }
    }

    gotoLogin = async () => {
        await setStorageData("name", "ChangePassword")
        this.props.navigation.navigate("EmailAccountLoginBlock")
    }

    handleChangeOldPassword = (value: string) => {
        this.setState({oldPassword: value, invalidOldPassword: false, changePasswordError: ''})
    }
    getUserRole = async () => {
        this.getUserRoleId = await this.apiCall({
          endpoint: configJSON.getUserRoleEndpoint,
          method: configJSON.GET
        });
      }
    handleChangeNewPassword = (newPassword: string) => {
        if (newPassword) {
            const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
            const validLength = newPassword.length >= 8;
            const validLowerCase = /[a-z]/.test(newPassword);
            const validSymbol = regex.test(newPassword);
            const validNumber = /\d/.test(newPassword);
            const validCapital = /[A-Z]/.test(newPassword);

            this.setState({
                invalidCapitalLetter: !validCapital,
                invalidLowercaseLetter: !validLowerCase,
                invalidLength: !validLength,
                invalidSymbol: !validSymbol,
                invalidNumber: !validNumber,
            });
        }
        this.setState({newPassword, invalidPassword: false, changePasswordError: ''})
    }
    handleChangeRetypePassword = (value: string) => {
        this.setState({retypePassword: value, invalidPassword: false, changePasswordError: ''})
    }
    toggleShowOldPassword = () => {
        this.setState({visibleOldPassword: !this.state.visibleOldPassword})
    }
    toggleShowNewPassword = () => {
        this.setState({visibleNewPassword: !this.state.visibleNewPassword})
    }

    toggleShowConfirmPassword = () => {
        this.setState({visibleConfirmPassword: !this.state.visibleConfirmPassword})
    }

    isDisableButton = () => {
        const {oldPassword, newPassword, retypePassword, 
            invalidCapitalLetter,
            invalidLength,
            invalidLowercaseLetter,
            invalidNumber,
            invalidSymbol} = this.state
        return oldPassword.length === 0 || newPassword.length === 0 || retypePassword.length === 0 
        ||  invalidCapitalLetter || invalidLength || invalidLowercaseLetter || invalidNumber || invalidSymbol
    }

    apiCall = async (params: {body?: object, endpoint: string, method: string}) => {
        const token = await getStorageData('authToken')
        const {body, endpoint, method} = params
        const reqMsg = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        
        reqMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
            "Content-Type": 'application/json',
            token
          })
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
          );
        body && reqMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
          );
        reqMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
       
        runEngine.sendMessage(reqMsg.id, reqMsg);
        return reqMsg.messageId
      }

    handleSaveChangePassword = async () => {
        if (this.state.newPassword !== this.state.retypePassword) {
            this.setState({ invalidPassword: true });
            return;
        };
        const token = await getStorageData('authToken')
        const body = {
            data: {
                attributes: {
                    password: this.state.newPassword,
                    old_password: this.state.oldPassword,
                    password_confirmation: this.state.retypePassword
                },
            }
        };
        const reqMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.changePasswordCallId = reqMsg.messageId
          reqMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.savePasswordChangeEndpoint
          );
      
          reqMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
              "Content-Type": 'application/json',
              token
            })
          );
          reqMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
          );
          reqMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.POST
          );
          
          runEngine.sendMessage(reqMsg.id, reqMsg);
    }
    toggleDialogSuccess = () => {
        this.setState({isOpenDialog: !this.state.isOpenDialog})
    }
}
// Customizable Area End
