// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");
export type Tabtype = 'status-tracker' | 'audit-info' | 'cap' | ''

export interface AuditInfo {
    audit_number: string,
    form_name: string,
    location_name: string,
    address: string,
    in_time: string,
    out_time:string,
    pic_name: string,
    points_given: string,
    points_applicable: string
    account_name: string,
    status: string,
    assigned_to: string,
    date_created: string,
    date_completed: string,
    building_pictures: Array<string>
}

export interface Props {
    id: string
    navigation: any;
}

interface S {
    activeTab: Tabtype
    auditId: string
    auditInfo: AuditInfo,
    isLoading: boolean,
    isRoleLoading: boolean,
    userRole: {role: string, id: string, group: 'evermore' | 'client' | 'regular'}
    isOpenDialogSuccess: boolean
    auditNotFound: boolean
}

interface SS {
    navigation: any;
}

export default class AuditInfoController extends BlockComponent<
    Props,
    S,
    SS
> {
    getAuditInfoId: string = ""
    getUserRoleId: string = ""
    completeAuditCallId: string = ""
    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            activeTab: 'audit-info',
            auditId: '',
            isRoleLoading: true,
            auditInfo: {
                audit_number: "",
                form_name: "",
                location_name: "",
                address: "",
                in_time: "",
                out_time: "",
                pic_name: "",
                points_given: "",
                points_applicable: "",
                account_name: "",
                status: "",
                assigned_to: "",
                date_created: "",
                date_completed: "",
                building_pictures: [],
            },
            isLoading: true,
            userRole: {
                role: "",
                id: "", 
                group: "regular"
            },
            isOpenDialogSuccess: false,
            auditNotFound: false
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        await this.getUserRole()
        const auditId = await this.props.navigation.getParam('id')
        this.setState({auditId})
        await this.getAuditInfo()
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
              );
        
              let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
              );    
            if (responseJson.data || responseJson.meta || responseJson.message) {
                this.handleGetDataSuccess(apiCallId, responseJson)
            } else if(responseJson.errors) {
                this.handleResponseErr(responseJson.errors)
            }
        }
    }

    getUserGroup = (role: string) => {
        const evermoreUserGroup = ['All Access User', 'Account Manager', 'Team Leader', 'Auditor']
        const clientUserGroup = ['Super User', 'Regional Manager', 'Area Manager', 'General Manager']
        if(evermoreUserGroup.includes(role)) return 'evermore'
        if(clientUserGroup.includes(role)) return 'client'
        return 'regular'
    }

    handleGetDataSuccess = async (apiCallId: string, responseJson: any) => {
        if (apiCallId === this.getUserRoleId) {
            this.setState({
                userRole: { ...responseJson.meta, group: this.getUserGroup(responseJson.meta.role)},
                isRoleLoading: false
            })
        }
        if (apiCallId === this.getAuditInfoId) {
            this.setState({ auditInfo: {...responseJson.data.attributes}, isLoading: false })
        }
        if(apiCallId === this.completeAuditCallId) {
            this.setState({isOpenDialogSuccess: true})
            await this.getAuditInfo()
        }
    }

    handleResponseErr = (errors: Array<any> | string) => {
        const errorList: {[key: string] : string} = {};
        Array.isArray(errors) && errors.forEach((error: Object) => {
            errorList[Object.keys(error)[0]] = Object.values(error)[0]
        });
        if(errorList['token']) {this.goLogin()}
        else if(errors) {
            this.setState({auditNotFound: true})
        }
    }

    onChangeTab = (tab: Tabtype) => {
        if(tab === 'audit-info') this.props.navigation.navigate('AuditInfo', {id: this.state.auditId})
        if(tab === 'cap') this.props.navigation.navigate('CAP', {id: this.state.auditId})
        if(tab === 'status-tracker') this.props.navigation.navigate('StatusTracker', {id: this.state.auditId})
     }
     goLogin = async () => {
        const auditId = await this.props.navigation.getParam('id')
        await setStorageData("name", "AuditInfo")
        await setStorageData("id", auditId)
        this.props.navigation.navigate("EmailAccountLoginBlock")
    }

    onCloseDialog = () => {
        this.setState({isOpenDialogSuccess: false})
    }

    isActiveTab = (currentTab: string) => {
        return this.state.activeTab === currentTab ? 'active' : ''
    }
    gotoConductAudit = () => {
        this.props.navigation.navigate("ConductAudit", {id: this.state.auditId})
    }
    gotoForm = () => {
        this.props.navigation.navigate("CriticalForm", {id: this.state.auditId})
    }
    getAuditInfo = async () => {
        const auditNo = await this.props.navigation.getParam('id');
        this.getAuditInfoId = await this.apiCall({
            method: configJSON.getApiMethod, 
            endpoint: `${configJSON.getAuditInfoAPIEndpoint}/${auditNo}`,
            contentType: 'application/json'
        })
    }

    handleCompleteAudit = async () => {
        const {auditId} = this.state;
        const reqBody = {
            audit: {
                status: "completed"
            }
        }
        this.completeAuditCallId = await this.apiCall({
            endpoint: `${configJSON.updateAuditEndpoint}/${auditId}/complete_audit`, 
            body: reqBody,
            method: configJSON.patchApiMethod
        })
    }

    getUserRole = async () => {
        this.getUserRoleId = await this.apiCall({
            method: configJSON.getApiMethod,
            endpoint: configJSON.getUserRoleEndpoint,
            contentType: 'application/json'
        })
    }

    apiCall = async (reqData: {method: string, endpoint: string, body?: {}, contentType?: string}) => {
        const token = await getStorageData('authToken')
        const {endpoint, method, body, contentType} = reqData
        const reqMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": contentType,
                token
            })
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && reqMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );
        runEngine.sendMessage(reqMsg.id, reqMsg);
        return reqMsg.messageId
    }
}
// Customizable Area End
