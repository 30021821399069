Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";
exports.patchApiMethod = "PATCH";

exports.groupsApiEndPoint = "account_groups/groups";
exports.tasksListApiEndPoint = "tasks/task_lists";
exports.tasksApiEndPoint = "tasks/tasks";
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.selectTaskPriority = "Select task prority";
exports.selectTaskStatus = "Select task status";
exports.selectAccount = "Select account";
exports.selectGroup = "Select group";
exports.selectTasks = "Select tasks";
exports.textTitle = "Title";
exports.textDescription = "Description";
exports.textAdd = "Add";
exports.textTasks = "Tasks";
exports.textTaskList = "Task Lists";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textSave = "Save";
exports.textClose = "Close";
exports.textAccounts = "Accounts";
exports.textGroups = "Groups";
exports.textAddTask = "Add Task";
exports.textAddTaskList = "Add Task List";
exports.textShowTaskList = "Show Task List";
exports.textShowTask = "Show Task";
exports.textId = "Id";
exports.textPriority = "Priority";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textOr = "OR";
exports.auditInfo = "Audit info"
exports.statusTracker = "Status Tracker";
exports.CAP = "Corrective Action Plan";
exports.capStatus = "CAP Status:";
exports.assigned = "Assigned";
exports.inProgress = "In Progress";
exports.completed = "Completed";
exports.accountName = "Account Name"
exports.locationName = "Location Name"
exports.assignTo = "Assigned To"
exports.dateCreated = "Date Created"
exports.address = "Address"
exports.inTime = "In-Time"
exports.outTime = "Out-Time"
exports.picName = "PIC Name"
exports.pointGiven = "Points Given"
exports.pointApplicable = "Points Applicable"
exports.buildingPictures = "Building Pictures"
exports.reviewAndEdit = "Review & Edit"
exports.review = "Review"
exports.auditId = "Audit ID"
exports.accountForm = "Form"
exports.auditAllocatorEndpoint = "bx_block_tasks/audits/audit_allocation"
exports.getNameEndpoint = "bx_block_tasks/audits/get_names"
exports.qsr = "QSR"
exports.fineDining = "Fine Dining"
exports.groceryStore = "Grocery Store"
exports.submitCap = "Submit Cap"
exports.saveCapEndpoint = "bx_block_custom_forms/corrective_action_plans"
exports.createCapSuccess = " CAP is submitted "
exports.getCapEndpoint = "/bx_block_custom_forms/corrective_action_plans"
exports.conductAudit = "Conduct Audit"
exports.inTime = "In Time"
exports.outTime = "Out Time"
exports.picName = "PIC Name"
exports.filloutreport = "Fill out report"
exports.submitAudit = "Submit audit"
exports.submitAuditSuccessMsg = 'Audit submitted successfully!'
exports.auditReportRules = "Audit Report Rules"
exports.submitAuditForReview = "Submit audit for review"
exports.getStatusTrackerEndpoint = "bx_block_tasks/audits"
exports.conductAudit = "Conduct Audit"
exports.getAuditInfoAPIEndpoint = "bx_block_tasks/audits"
exports.noImage = "No pictures available"
exports.dataEmpty = "No data is available."
exports.dataEmptyForComplete = "There is no corrective action plan, there is no OUT answer."
exports.getUserRoleEndpoint = "account_block/accounts/get_user_role"
exports.noAccess = "You don't have permission to access this page. Please contact admin!"
exports.auditAllocator = "Audit Allocator"
exports.typeOfAudit = "Form Name"
exports.auditorName = "Auditor Name"
exports.regionName = "Region Name"
exports.departmentName = "Department Name"
exports.getUserRoleEndpoint = "account_block/accounts/get_user_role"
exports.underReview = "Under Review"
exports.viewReport = "View Report"
exports.sendNow = "Send Now"
exports.completeAudit = "Complete Audit"
exports.evermore = ['all access user', 'account manager', 'team leader', 'auditor']
exports.noPermision = "You don't have permission to access this page. Please contact admin!"
exports.fromDate = "From"
exports.toDate = "To"
exports.auditNumber = "Audit Number"
exports.status = "Status"
exports.assignedTo = "Assigned To"
exports.dateCompleted = "Date Completed"
exports.getAuditListEndpoint = "bx_block_tasks/audits/audit_list"
exports.getFilterDataEndpoint = "bx_block_tasks/audits/get_names"
exports.noRecord = "No record found!"
exports.updateAuditEndpoint = "bx_block_tasks/audits"
exports.deleteAudit = "Delete Audit"
exports.auditList = "Audit List"
exports.searchAuditEndpoint = "bx_block_tasks/audits/search_audits"
exports.searchResultFor = "Search results for"
exports.submitInfoSuccess = "Information is submitted successfully"
exports.filterAccountEndpoint = "account_block/accounts/filtered_accounts"
exports.notFound = "Audit doesn't exist!"
exports.getFormNameEndpoint = "bx_block_custom_forms/index"
exports.deleteConductImageEndpoint = "bx_block_tasks/audits/destroy_building_picture"
exports.getListDepartmentEndpoint = "account_block/accounts/fetch_location_departments"
exports.getListLocationEndpoint = "account_block/accounts/fetch_region_locations"
exports.getUserRegionEndpoint = "account_block/accounts/fetch_user_regions"
exports.getAccountNameEndpoint = "account_block/accounts/fetch_users"
// Customizable Area End
