// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { createRef } from "react";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");
export interface Props {
    id: string
    navigation: any;
}

interface S {
    auditId: string,
    listPicName: Array<string>
    picName: string
    listImage: Array<any>
    originalListImg: Array<any>
    inTime: string
    outTime: string
    openDialog: boolean
    formSubmitted: boolean
    time: {hour: string, min: string}
    width: number
    user: {id: string, role: string, group: 'evermore' | 'client' | ''}
    isRoleLoading: boolean
    isLoading: boolean
    locationName: string
    status: 'assigned' | 'under_review' | 'completed'
    isLoadingSubmit: boolean
    imgRemoveId: number
}

interface SS {
    navigation: any;
}

export default class ConductAuditController extends BlockComponent<
    Props,
    S,
    SS
> {
    inputImgRef: any
    submitAuditCallId: string = ""
    getRoleCallId: string = ""
    getAuditInfoId: string = ""
    submitStatusCallId: string = ""
    deleteImageCallId: string = ""
    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            picName: '',
            listPicName: ['GM', 'AGM', 'Shift Leader', 'PIC', 'Other'],
            listImage: [],
            inTime: '00:00',
            outTime: '',
            auditId: '',
            openDialog: false,
            formSubmitted: false,
            time: {
                min: '',
                hour: ''
            },
            width: window.innerWidth,
            isRoleLoading: true, 
            user: {
                id: "", 
                role: "", 
                group: ""
            },
            isLoading: true,
            locationName: "",
            status: 'assigned',
            isLoadingSubmit: false,
            originalListImg: [],
            imgRemoveId: -1
        }
        this.inputImgRef = createRef()
    }

    async componentDidMount() {
        super.componentDidMount();
        await this.getUserRoles()
        await this.getAuditId()
        window.addEventListener('resize', () => {this.setState({width: window.innerWidth})})
    }

    async componentWillUnmount(){
        window.removeEventListener('resize', () => {this.setState({width: window.innerWidth})})
    }

    getAuditId = async () => {
        const auditId = await this.props.navigation.getParam('id');
        this.setState({auditId}, () => {this.getAuditInfo()})
    }

    getUserRoles = async () => {
        this.getRoleCallId = await this.apiCall({
            method: configJSON.getApiMethod,
            endpoint: configJSON.getUserRoleEndpoint,
            contentType: 'application/json'
        })
    }

    getAuditInfo = async () => {
        this.getAuditInfoId = await this.apiCall({
            method: configJSON.getApiMethod, 
            endpoint: `${configJSON.getAuditInfoAPIEndpoint}/${this.state.auditId}`
        })
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
              );
        
              let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
              );  
              if(apiCallId === this.submitAuditCallId){
                if(responseJson.data) this.setState({isLoadingSubmit: false}, () => {
                    this.setState({openDialog: true})
                })
              }     
              if(apiCallId === this.getRoleCallId){
               this.handleRoleResponse(responseJson)
              }
              if(apiCallId === this.getAuditInfoId) {
                this.handleAuditInfoResponse(responseJson)
              }
              if(apiCallId === this.submitStatusCallId) {
                this.handleStatusResponse(responseJson)
              }
              if(apiCallId === this.deleteImageCallId) {
                this.handleDeleteImgResponse(responseJson)
              }
        }
    }

    handleDeleteImgResponse = (responseJson: any) => {
        if (responseJson.message === "Building picture deleted successfully") {
            const { listImage, imgRemoveId } = this.state

            const item = listImage.find(item => item.id === imgRemoveId);
            const index = listImage.indexOf(item);
            listImage.splice(index, 1)
            this.setState({imgRemoveId: -1, listImage: [...listImage]})
        }
    }

    handleStatusResponse = (responseJson: any) => {
        if(responseJson.data) this.setState({openDialog: true, status: 'under_review'})
        else if(responseJson.errors) {
            this.handleResponseError(responseJson.errors)
        }
    }

    handleRoleResponse = (responseJson: any) => {
        if (responseJson.meta) {
            const group = configJSON.evermore.includes(responseJson.meta.role) ? 'evermore' : 'client'
            this.setState({ user: { ...responseJson.meta, group }, isRoleLoading: false })
        } else if (responseJson.errors) {
            this.handleResponseError(responseJson.errors)
        }
    }

    toDataURL = async (url: string) => {
        const result = await fetch(url)
        const blob = await result.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        })

    }
    dataURLtoBlob = (dataurl: any) => {
        const totalMime = dataurl.split(';')[0]
        const mime = totalMime.split(':')
        let arr = dataurl.split(','), bstr = atob(arr[1]), numbers = bstr.length, u8arr1 = new Uint8Array(numbers);
        while (numbers--) {
            u8arr1[numbers] = bstr.charCodeAt(numbers);
        }

        return new Blob([u8arr1], { type: mime });
    }

    handleAuditInfoResponse = async (responseJson: any) => {
        if (responseJson.data) {
            const { attributes } = responseJson.data;
            const inTime=attributes.in_time??"00:00";
            const [hour,min]=inTime.split(":");
            let listImage: Array<any> = [];
            this.setState({
                picName: attributes.pic_name ?? "",
                inTime,
                time:{
                    hour:hour||'',
                    min:min||''
                },
                outTime: attributes.out_time ?? "",
                formSubmitted: attributes.out_time ? true : false,
                locationName: attributes.location_name ?? "",
                isLoading: false,
            });
            
            for(let image of attributes.building_pictures ){
                            const dataUrl = await this.toDataURL(image.url);
                            const item = this.dataURLtoBlob(dataUrl);
                            listImage.push({ id: image.picture_id, source: image.url, origin: item })
                        }
                        this.setState({
                            listImage,
                            originalListImg: [...listImage],
                            isLoading: false,
                        })
        }
    }
    

    handleResponseError = (errors: Array<any>) => {
        const errList: {[key: string] : string} = {};
        Array.isArray(errors) && errors.forEach((error: Object) => {
            errList[Object.keys(error)[0]] = Object.values(error)[0]
        });
        if(errList['token']) {
            removeStorageData('authToken');
            this.goLogin()
        }
    }

    goLogin = async () => {
        const auditId = await this.props.navigation.getParam('id')
        await setStorageData("name", "ConductAudit")
        await setStorageData("id", auditId)
        this.props.navigation.navigate("EmailAccountLoginBlock")
    }

    onCloseDialog = () => {
        this.setState({openDialog: false, formSubmitted: true})
        if(this.state.status === 'assigned') this.gotoReport()
        else this.gotoAuditInfo()
    }

    onChangePicName = (picName: string) => {
        this.setState({picName})
    }

    onClickUploadImage = () => {
        this.inputImgRef.current?.click()
    }
    onUploadImage = (files: any) => {
        const {listImage} = this.state
        for(let i of files) {
            listImage.push({
                id: listImage.length - 1 + URL.createObjectURL(i),
                source: URL.createObjectURL(i),
                origin: i
            }
        )}
        this.setState({listImage: [...listImage]})
    }

    removeImageItem = async (id: number) => {
        const { listImage, auditId, originalListImg } = this.state
        const index = listImage.findIndex(item => item.id===id);
        const indexInOriginal = originalListImg.findIndex(item => item.id === id)

        if (indexInOriginal > -1) {
            this.setState({ imgRemoveId: id })

            this.deleteImageCallId = await this.apiCall({
                method: configJSON.deleteApiMethod,
                endpoint: `${configJSON.deleteConductImageEndpoint}?audit_id=${auditId}&image_id=${id}`
            })
        } else {
            listImage.splice(index, 1);
            this.setState({ listImage: [...listImage] })
        }
    }

    onChangeTime = (type: 'h' | 'm', targetTime: any) => {
        const { time } = this.state;
        if (type === 'h') {
            time.hour = targetTime;
        } else if (type === 'm') {
            time.min = targetTime;
        }
        const updatedHour = time.hour ? time.hour : '00';
        const updatedMinute = time.min ? time.min : '00';
        this.setState({ 
            inTime: `${updatedHour}:${updatedMinute}`, 
            time: { ...time } 
        });
    };
    

    gotoReport = async () => {
        // window.open(window.location.origin+'/form/'+this.state.auditId, '_blank')
        await setStorageData('isConductAudit', true)
        this.props.navigation.navigate("CriticalForm", {id: this.state.auditId})
    }

    gotoAuditInfo = () => {
        this.props.navigation.navigate("AuditInfo", {id: this.state.auditId})
    }

    apiCall = async (parameters: {reqBody?: object, endpoint: string, method: string, contentType?: string}) => {
        const token = await getStorageData("authToken");
        const {reqBody, endpoint, method, contentType} = parameters
        const reqMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": contentType,
                token
            })
        );
        
        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            reqBody
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(reqMsg.id, reqMsg);
        return reqMsg.messageId
    }

    disableButton = () => {
        const { inTime, picName, time, isLoadingSubmit } = this.state;
        const isTimeValid = time.hour.length > 0 && time.min.length > 0 && time.hour!='00';
        return inTime.length === 0 || picName.length === 0 || !isTimeValid || isLoadingSubmit;
    }

    getListNewImage = () => {
        const {listImage, originalListImg} = this.state;
        const listNewImg: any = [];

        for (let imgItem of listImage) {
            const isItemInOriginal = originalListImg.find(item => item.id === imgItem.id);
            if(!isItemInOriginal) listNewImg.push(imgItem)
        }

        return listNewImg

    }

    handleSubmitAudit = async () => {
        const { time, picName, auditId } = this.state;
        const inTime = time.hour + ':' + time.min;
        const reqBody = new FormData();
        reqBody.append("audit[in_time]", inTime);
        reqBody.append("audit[pic_name]", picName);
    
        this.setState({ isLoadingSubmit: true });
        const listNewImg = this.getListNewImage();
        listNewImg.forEach((imageItem: any) => reqBody.append("audit[building_pictures][]", imageItem.origin));
    
        this.submitAuditCallId = await this.apiCall({
            endpoint: `${configJSON.updateAuditEndpoint}/${auditId}/conduct_audit`,
            reqBody,
            method: configJSON.putApiMethod
        });
    }
    
    handleSubmitAuditToReview = async () => {
        const reqBody = new FormData();
        const {auditId} = this.state;
        reqBody.append("audit[status]", "under_review")
        this.submitStatusCallId = await this.apiCall({
            endpoint: `${configJSON.updateAuditEndpoint}/${auditId}/conduct_audit`, 
            reqBody,
            method: configJSON.putApiMethod
        })
    }
}
// Customizable Area End
