// Customizable Area Start
import React from "react";

import {
    Container,
    Box,
    Typography,
    Grid,
    Input,
    InputAdornment,
    IconButton,
    Button,
    Dialog,
    DialogContent
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../../blocks/navigationmenu/src/Header.web";
import VisibilityOff from "@material-ui/icons/VisibilityOffOutlined";
import Visibility from "@material-ui/icons/VisibilityOutlined";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { imgInvalid, imgValid } from "./assets";

const theme = createTheme({
    overrides: {
        MuiPaper: {
            rounded: {
                borderRadius: '8px 8px 32px 8px'
            },
            root: {
                fontFamily: "'Montserrat', sans-serif !important"
            },
        },
        MuiInputBase: {
            input: {
                fontFamily: "'Montserrat', sans-serif !important"
            }
        },
        MuiTypography: {
            body1: {
                fontFamily: "'Montserrat', sans-serif"
            },
            root: {
                fontFamily: "'Montserrat', sans-serif"
            },
        },
    }
});

import ChangePasswordController, {
    Props,
    configJSON,
} from "./ChangePasswordController";

export default class ChangePassword extends ChangePasswordController {
    constructor(props: Props) {
        super(props);
    }

    getVisibilityImg = (show: boolean) => {
        return show ? <VisibilityOff /> : <Visibility />
    }

    PasswordShowHideIcon = (showHidePassword: () => void, visiblePassword: boolean) => {
        return <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                data-test-id={"btn-show-hide-password"}
                onClick={showHidePassword}
                edge="end"
            >
                {this.getVisibilityImg(visiblePassword)}
            </IconButton>
        </InputAdornment>
    }

    PasswordUnmatch = () => {
        return this.state.invalidPassword && <Typography style={styles.errorText}>Passwords do not match</Typography>
    }

    PasswordValidation = () => {
        return <Box>
            <CustomTypo style={{ marginBottom: 8 }}>Your password must contain</CustomTypo>
            <PasswordValidationItem>
                <img
                    style={styles.icon}
                    src={this.getValidationImage(!this.state.invalidLowercaseLetter)}
                    alt="icon"
                />
                <Typography className="text">{configJSON.oneLowercase}</Typography>
            </PasswordValidationItem>
            <PasswordValidationItem>
                <img
                    style={styles.icon}
                    src={this.getValidationImage(!this.state.invalidCapitalLetter)}
                    alt="icon"
                />
                <Typography className="text">{configJSON.ruleCapitalLetter}</Typography>
            </PasswordValidationItem>
            <PasswordValidationItem>
                <img
                    style={styles.icon}
                    src={this.getValidationImage(!this.state.invalidLength)}
                    alt="icon"
                />
                <Typography className="text">{configJSON.minimumLength}</Typography>
            </PasswordValidationItem>
            <PasswordValidationItem>
                <img
                    style={styles.icon}
                    src={this.getValidationImage(!this.state.invalidNumber)}
                    alt="icon"
                />
                <Typography className="text">{configJSON.oneNumber}</Typography>
            </PasswordValidationItem>
            <PasswordValidationItem>
                <img
                    style={styles.icon}
                    src={this.getValidationImage(!this.state.invalidSymbol)}
                    alt="icon"
                />
                <Typography className="text">{configJSON.oneSymbol}</Typography>
            </PasswordValidationItem>
        </Box>
    }

    getValidationImage = (isValid: boolean) => {
        return isValid ? imgValid : imgInvalid
    }

    DialogUpdateSuccess = () => {
        return <Dialog fullWidth open={this.state.isOpenDialog} onClose={this.toggleDialogSuccess}>
            <DialogContent dividers>
                <CloseIcon data-test-id="icon-close-dialog" onClick={this.toggleDialogSuccess} style={styles.closeIcon as React.CSSProperties} />
                <Box style={styles.dialogContent as React.CSSProperties}>
                    {this.state.changePasswordError.length > 0 ?
                        <CancelOutlinedIcon style={{ color: "#DC2626", height: 50, width: 50 }} />
                        : <CheckCircleOutlineIcon style={{ color: "#34D399", height: 50, width: 50 }} />}
                    <Typography align="center" style={{ ...styles.successText }}>
                        {this.state.changePasswordError.length > 0 ? this.state.changePasswordError : "Password Updated Successfully!"}
                    </Typography>
                </Box>
            </DialogContent>
        </Dialog>
    }

    CheckPermission = () => {
        const { userRole } = this.state
        return userRole === 'regular' ? <Typography style={{ margin: '30px auto' }}>{configJSON.noPermission}</Typography> :
            <ChangePasswordContainer maxWidth={false}>
                <Box className="content-container">
                    <Typography className="heading">{configJSON.ChangePassword}:</Typography>
                    <Grid container>
                        <Grid item container md={9} xs={12} spacing={2}>
                            <Grid item sm={6} xs={12}>
                                <CustomTypo className="label">{configJSON.oldPassword}</CustomTypo>
                                <MyInput
                                    data-test-id="txtInputOldPassword"
                                    placeholder={"Enter old password"}
                                    type={this.state.visibleOldPassword ? "text" : "password"}
                                    fullWidth={true}
                                    value={this.state.oldPassword}
                                    onChange={(e) => { this.handleChangeOldPassword(e.target.value) }}
                                    style={{ borderColor: this.state.invalidOldPassword ? configJSON.colorError : "#CBD5E1" }}
                                    endAdornment={this.PasswordShowHideIcon(this.toggleShowOldPassword, this.state.visibleOldPassword)}
                                    disableUnderline
                                    name="old-password"
                                    autoComplete="new-password" />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <CustomTypo className="label">{configJSON.newPassword}</CustomTypo>
                                <MyInput
                                    data-test-id="txtInputNewPassword"
                                    placeholder={"Enter new password"}
                                    type={this.state.visibleNewPassword ? "text" : "password"}
                                    fullWidth={true}
                                    value={this.state.newPassword}
                                    onChange={(e) => { this.handleChangeNewPassword(e.target.value) }}
                                    style={{ borderColor: this.state.invalidPassword ? configJSON.colorError : "#CBD5E1" }}
                                    endAdornment={this.PasswordShowHideIcon(this.toggleShowNewPassword, this.state.visibleNewPassword)}
                                    disableUnderline />
                                {this.PasswordUnmatch()}
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <CustomTypo className="label">{configJSON.retypePassword}</CustomTypo>
                                <MyInput
                                    data-test-id="txtInputRetypePassword"
                                    placeholder={"Enter confirm password"}
                                    type={this.state.visibleConfirmPassword ? "text" : "password"}
                                    fullWidth={true}
                                    value={this.state.retypePassword}
                                    onChange={(e) => { this.handleChangeRetypePassword(e.target.value) }}
                                    style={{ borderColor: this.state.invalidPassword ? configJSON.colorError : "#CBD5E1" }}
                                    endAdornment={this.PasswordShowHideIcon(this.toggleShowConfirmPassword, this.state.visibleConfirmPassword)}
                                    disableUnderline />
                                {this.PasswordUnmatch()}
                            </Grid>
                        </Grid>
                        <Grid item md={3} className="space"></Grid>
                    </Grid>
                    {this.state.newPassword.length > 0 && this.PasswordValidation()}

                    <MyButton data-test-id="btn-save-change" onClick={this.handleSaveChangePassword} disabled={this.isDisableButton()}>
                        <Typography>{configJSON.ChangePassword}</Typography>
                    </MyButton>
                </Box>
            </ChangePasswordContainer>
    }

    render() {
        return (
            <Header navigation={this.props.navigation} >
                <ThemeProvider theme={theme}>
                    {this.CheckPermission()}
                    {this.DialogUpdateSuccess()}
                </ThemeProvider>
            </Header>
        );
    }
}

const styles = {
    errorText: {
        fontSize: 14,
        color: "#DC2626",
        marginTop: 5
    },
    icon: {
        width: "12px",
        height: "12px",
        marginRight: "6px",
    },
    dialogContent: {
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: "column",
    },

    closeIcon: {
        position: "absolute",
        top: 10,
        right: 10,
        color: "#334155",
        cursor: "pointer",
    },
    successText: {
        lineHeight: "32px",
        fontWeight: 700,
        fontSize: 24,
        color: "#0F172A",
        marginTop: 10
    },
}


const PasswordValidationItem = styled(Box)({
    display: "flex",
    fontSize: "12px",
    lineHeight: "18px",
    alignItems: "center",
    marginBottom: 8,
    "& .text": {
        fontSize: 14,
        lineHeight: "20px",
        fontWeight: 400,
    }
})


const MyButton = styled(Button)({
    background: "linear-gradient(90deg, rgba(118,123,215,1) 0%, rgba(17,31,80,1) 100%)",
    padding: 16,
    borderRadius: 8,
    width: 'fit-content',

    "& p": {
        color: "#FFFFFF",
        lineHeight: "24px",
        fontSize: 16,
        fontWeight: 700,
    },
    "&:disabled": {
        background: "#A9A9A9 !important",
        color: "#FFFFFF",
        cursor: "not-allowed",
    },
    "@media (max-width: 600px)": {
        alignSelf: 'center',
        "& p": {
            fontSize: 14
        }
    }
})

const MyInput = styled(Input)({
    border: "1px solid #CBD5E1",
    borderRadius: 8,
    marginTop: 5,
    padding: 10,
})

const CustomTypo = styled(Typography)({
    color: '#0F172A',

    "&.label": {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '24px'
    },
    "@media (max-width: 475px)": {
        "&.label": {
            fontSize: 14,
            lineHeight: '22px'
        },
    }
})

const ChangePasswordContainer = styled(Container)({
    overflowY: 'auto',
    height: '100%',
    background: "#F1F5F9",
    paddingTop: 30,
    paddingBottom: 30,
    "& .content-container": {
        display: 'flex',
        flexDirection: 'column',
        gap: 30,
        background: '#FFFFFF',
        borderRadius: 8,
        border: "1px solid #CBD5E1",
        minHeight: '100%',
        height: 'auto',
        padding: "25px 20px",
        boxSizing: 'border-box',
    },
    "& .heading": {
        fontSize: 24,
        lineHeight: "32px",
        fontWeight: 700,
        color: '#0F172A',
        marginBottom: 20
    },
    "@media (max-width: 960px)": {
        '& .space': {
            display: 'none'
        }
    },
    "@media (max-width: 600px)": {
        "& .content-container": {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    "@media (max-width: 475px)": {
        "& .heading": {
            fontSize: 20,
        }
    }
})
// Customizable Area End
