import React from "react";

// Customizable Area Start
import { Container, Box, Typography, Grid, Select, Checkbox, Button, Drawer, ButtonBase, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, TextField, Input, CircularProgress, MenuItem, Chip } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../../blocks/navigationmenu/src/Header.web";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { icUpload } from "./assets";
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import { Autocomplete, Pagination, PaginationItem } from "@material-ui/lab";
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
// Customizable Area End

import VisualAnalyticsController, {
  Props,
  configJSON,
} from "./VisualAnalyticsController";

export default class VisualAnalytics extends VisualAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  DatePickerRange = () => {
    return <SelectDateBox>
      <Box className="select-date-item">
        <Typography className="title">{configJSON.from}</Typography>
        <DatePicker
          selected={this.state.fromDate}
          dateFormat="MMM dd, yyyy"
          className="dateInput"
          data-test-id="dateFilterFrom"
          maxDate={this.state.toDate ?? new Date()}
          popperPlacement="bottom-start"
          placeholderText="mm dd, yyyy"
          onChange={(date: Date) => this.handleChangeFromDate(date)}
          customInput={<Input disableUnderline endAdornment={<CalendarTodayRoundedIcon className="icon-calendar" />} />}
        />
      </Box>
      <Box className="select-date-item">
        <Typography className="title">{configJSON.to}</Typography>
        <Box style={{ ...styles.flexBox, gap: 10, alignItems: 'center' }}>
          <DatePicker
            selected={this.state.toDate}
            onChange={(date: Date) => this.handleChangeToDate(date)}
            dateFormat="MMM dd, yyyy"
            className="dateInput"
            data-test-id="dateFilterTo"
            maxDate={new Date()}
            minDate={this.state.fromDate}
            popperPlacement="bottom-start"
            placeholderText="mm dd, yyyy"
            customInput={<Input disableUnderline endAdornment={<CalendarTodayRoundedIcon className="icon-calendar" />} />}
          />
          {(this.state.fromDate || this.state.toDate) &&
            <HighlightOffRoundedIcon data-test-id='btn-clear-date' onClick={this.removeDateRangePicker} style={{ color: '#F87171' }} />}
        </Box>

      </Box>
    </SelectDateBox>
  }

  DropdownLocation = (label: string, listOptions: any, selectedValue: any, onChange: (value: string) => void, isLoading?: boolean, isDisabled?: boolean) => {
    const showData = () => {
      return listOptions.length > 0 ? listOptions.map((oneOption: any) => {
        const isChecked = selectedValue.some((itemValue: any) => itemValue.id === oneOption.id)
        return <ItemSelect
          value={oneOption}
          data-test-id={"test-" + label}
          key={oneOption.id}
          onClick={() => onChange(oneOption)}>
          <Checkbox checked={isChecked} style={{ color: "#152764" }} />
          <Typography>{oneOption.name}</Typography>
        </ItemSelect>
      }) : <Typography>{configJSON.noRecord}</Typography>
    }
    return <DropdownCheckBoxContainer>
      <Typography className="title">{label}</Typography>
      <CustomSelect
        labelId="select-region-location-department"
        data-test-id={"container-" + label}
        disableUnderline
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          TransitionProps: { style: { padding: "0 4px", borderRadius: 8, marginTop: 20, maxHeight: 250 } },
          getContentAnchorEl: null,
          transformOrigin: {
            horizontal: 'center',
            vertical: 'top',
          }, 
        }}
        displayEmpty
        multiple
        disabled={(label === configJSON.status && this.state.user.group !== 'evermore') || isDisabled}
        renderValue={(value: any) => {
          return value.length > 0 ?
            <Box style={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
              {value.map((item: any) => (
                <Chip key={item.id} label={item.name} />
              ))}
            </Box> : 'Select here'
        }}
        IconComponent={ArrowDropDownRoundedIcon}
        value={selectedValue}
      >
        {isLoading? <CircularProgress size={20} style={{display: "block", margin: "0 auto"}} /> : showData()}
        
      </CustomSelect>
    </DropdownCheckBoxContainer>
  }

  DropdownWithCheckbox = (title: string, options: any, value: any, onChange: (value: string) => void, isDisabled?: boolean) => {
    
    return <DropdownCheckBoxContainer>
      <Typography className="title">{title}</Typography>
      <CustomSelect
        labelId="demo-customized-select-label"
        disableUnderline
        data-test-id={"container-" + title}
        MenuProps={{
          TransitionProps: { style: { padding: "0 4px", marginTop: 20, borderRadius: 8, maxHeight: 250 } },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          getContentAnchorEl: null,
          PaperProps: {
            onScroll: (event) => this.onScroll(title, event)
          }
        }}
        disabled={(title === configJSON.status && this.state.user.group !== 'evermore') || isDisabled}
        displayEmpty
        multiple
        renderValue={(value: any) => { return value.length > 0 ? value.join(', ') : 'Select here' }}
        value={value}
        IconComponent={ArrowDropDownRoundedIcon}
      >
        {options.length > 0 ? options.map((item: any) => {
          return <ItemSelect
            key={item}
            data-test-id={"test-" + title}
            onClick={() => onChange(item)}>
            <Checkbox checked={value.includes(item)} style={{ color: "#152764" }} />
            <Typography>{item}</Typography>
          </ItemSelect>
        }) : <Typography>{configJSON.noRecord}</Typography>}

      </CustomSelect>
    </DropdownCheckBoxContainer>
  }
  CustomAutoComplete = (id: string, title: string, listItem: Array<any>, value: Array<any>, onChange: (value: any) => void) => {
    const displayOption = (option: any) => {
      if(id === "accountName") return option.name
      return option?.full_name ?? option?.email ?? ""
    }
    return <DropdownCheckBoxContainer>
      <Typography className="title">{title}</Typography>

      <MyAutoComplete
        key={id}
        options={listItem}
        getOptionLabel={(option) => displayOption(option)}
        renderOption={(option) => <React.Fragment>
          <Checkbox checked={value.includes(option)} style={{ color: "#152764" }} />
          <Typography style={{wordBreak: 'break-word'}}>{displayOption(option)}</Typography>
        </React.Fragment>}
        renderInput={(params) => <TextField {...params}
         InputProps={{ ...params.InputProps, disableUnderline: true }} placeholder={value.length > 0? "" : "Select here"}  />}
        id={id}
        data-test-id={'select-' + id}
        onChange={(event, newValue) => {
          onChange(newValue)
        }}
        multiple
        value={value}
        disableCloseOnSelect
        popupIcon={<ArrowDropDownRoundedIcon style={{marginRight: -8}} />}
      />
    </DropdownCheckBoxContainer>

  }
  FilterBar = (params?: any) => {
    const { listFormName,
      listStatus,
      listAuditNumber,
      listRegionName,
      listLocationName,
      listDepartmentName,
      selectedFormName,
      selectedStatus,
      selectedAuditNumber,
      selectedRegionName,
      selectedLocationName,
      selectedDepartment,
      listAccountName,
      listAuditor,
      selectedAccountName,
      selectedAuditor, user,
      isLoadingDepartment, 
      isLoadingLocation,
      isLoadingRegion } = this.state
      const filteredStatus = user.group === 'evermore' ? listStatus : listStatus.filter(item => item === "Completed")
    return <FilterBarContainer style={{
      width: params?.width ? params?.width : 'auto',
      height: params?.height ? params?.height : 'auto'
    }}>
      {this.DropdownWithCheckbox(configJSON.formType, listFormName, selectedFormName, this.onChangeFormType)}
      {this.CustomAutoComplete('auditor', configJSON.auditorName, listAuditor, selectedAuditor, this.onChangeAuditor)}
      {this.DropdownWithCheckbox(configJSON.status, filteredStatus, selectedStatus, this.onChangeStatus)}
      {this.DropdownWithCheckbox(configJSON.auditNumber, listAuditNumber, selectedAuditNumber, this.onChangeAuditNumber)}
      {this.CustomAutoComplete('accountName', configJSON.accountName, listAccountName, selectedAccountName, this.onChangeAccountName)}
      {this.DropdownLocation(configJSON.regionName, listRegionName, selectedRegionName, this.onChangeRegionName, isLoadingRegion, selectedAccountName.length === 0)}
      {this.DropdownLocation(configJSON.locationName, listLocationName, selectedLocationName, this.onChangeLocationName, isLoadingLocation, selectedRegionName.length === 0)}
      {this.DropdownLocation(configJSON.departmentName, listDepartmentName, selectedDepartment, this.onChangeDepartmentName, isLoadingDepartment, selectedLocationName.length === 0)}
      <Box style={{ ...styles.flexBox, gap: 10, justifyContent: 'end' }}>
        <CustomButton data-test-id="btn-cancel" disabled={this.isDisabledFilterButton()} onClick={this.handleCancelFilter} className="button-cancel">
          <Typography className="text">{configJSON.cancel}</Typography>
        </CustomButton>
        <CustomButton data-test-id="btn-filter" disabled={this.isDisabledFilterButton()} onClick={() => this.handleFilterData(1)} className="button-filter">
          <Typography className="text">{configJSON.filter}</Typography>
        </CustomButton>
      </Box>
    </FilterBarContainer>
  }



  StatusBar = (status: string) => {
    let { color, backgroundColor, text, smallText } = this.findingStatusStyle(status)

    return <StatusBox style={{ backgroundColor, color }}>
      <Typography className="big-text" style={{ color }}>{text}</Typography>
      <Typography className="small-text" style={{ color }}>{smallText}</Typography>
    </StatusBox>
  }

  CustomTable = () => {
    const { reportTableData, width } = this.state;
    const isSmall = width < 820 || (width > 960 && width < 1070);

    return <CustomTableContainer>
      <Table className="table">
        <TableHead>
          <TableRow>
            <TableCell size="small" style={styles.stickyItem}>
              {isSmall ? "#" : configJSON.auditNumber}
            </TableCell>
            <TableCell>
              {configJSON.accountName}
            </TableCell>
            {!isSmall &&
              <TableCell>
                {configJSON.status}
              </TableCell>}
            <TableCell>
              {configJSON.assignedTo}
            </TableCell>
            <TableCell>
              {configJSON.dateCreated}
            </TableCell>
            <TableCell>
              {configJSON.dateCompleted}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="table-body">
          {reportTableData.length > 0 ?
            reportTableData.map(rowItem => {
              const rowAttributes = rowItem.attributes
              const { backgroundColor, text } = this.findingStatusStyle(rowAttributes.status);
              return <TableRow className="table-row" key={rowItem.id}>
                {isSmall ?
                  <Tooltip arrow placement="bottom-start" title={text}>
                    <TableCell align="center" className="table-cell" size="small"
                      style={{ ...styles.stickyItem, borderLeftColor: backgroundColor }}>{rowItem.id}</TableCell>
                  </Tooltip> :
                  <TableCell align="center" className="table-cell" size="small"
                    style={{ ...styles.stickyItem, borderLeftColor: backgroundColor }}>{rowItem.id}</TableCell>
                }
                <TableCell align="center">{rowAttributes.account_name}</TableCell>
                {!isSmall && <TableCell align="center">{this.StatusBar(rowAttributes.status)}</TableCell>}
                <TableCell align="center">{rowAttributes.assigned_to}</TableCell>
                <TableCell align="center">{rowAttributes.date_created}</TableCell>
                <TableCell align="center">{rowAttributes.date_completed}</TableCell>
              </TableRow>
            }) : <TableRow><TableCell colSpan={6} align="center"><Typography>{configJSON.noRecord}</Typography></TableCell></TableRow>}
        </TableBody>
      </Table>
    </CustomTableContainer>
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Header navigation={this.props.navigation}>
        <ThemeProvider theme={theme}>
          {this.state.isLoading ? <CircularProgress style={{ margin: "auto" }} /> :
            <ReportContainer maxWidth={false}>
              <Grid container spacing={2}>
                <Grid item lg={3} className="filter-bar">
                  {this.FilterBar()}
                </Grid>
                <Grid item lg={9} xs={12}>
                  <Box style={{ ...styles.flexBox, alignItems: 'end', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: 20 }}>
                    {this.DatePickerRange()}
                    <CustomButton disabled={this.state.disableExportButton} data-test-id="btn-download-csv" onClick={this.downloadCsv}>
                      <img src={icUpload} style={{ marginRight: 10 }} />
                      <Typography className="text">{configJSON.exportDetailReport}</Typography>
                    </CustomButton>
                  </Box>
                  {this.CustomTable()}
                  <CustomPagination size="small" count={this.state.totalPage} siblingCount={1}
                    onChange={(event, page) => { this.onChangePage(page) }}
                    page={this.state.currentPage}
                    data-test-id="pagination"
                    renderItem={(item) => <PaginationItem {...item} className="pagination-item" />} />

                </Grid>
              </Grid>
              <FilterIconContainer onClick={this.toggleDrawerFiler}>
                <FilterListIcon />
              </FilterIconContainer>
              <Drawer anchor="right" open={this.state.openDrawerFilter} onClose={this.toggleDrawerFiler}>
                {this.FilterBar({ width: 250, height: "fit-content" })}
              </Drawer>
            </ReportContainer>}
        </ThemeProvider>

      </Header>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  flexBox: {
    display: "flex",
  },
  stickyItem: {
    position: "sticky",
    left: 0,
    zIndex: 10
  } as React.CSSProperties
}

const theme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "'Montserrat', sans-serif"
      },
      body1: {
        fontFamily: "'Montserrat', sans-serif"
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: "'Montserrat', sans-serif !important",
      }
    },
    MuiPaper: {
      root: {
        fontFamily: "'Montserrat', sans-serif !important"
      }
    },
    MuiTableCell: {
      root: {
        fontFamily: "'Montserrat', sans-serif !important"
      },
      head: {
        color: "#475569",
        background: "#E5E5FF",
        textAlign: 'center',
        fontWeight: 700
      },
      body: {
        background: 'white'
      }
    },
    MuiTableSortLabel: {
      icon: {
        display: 'block !important',
        opacity: "1 !important"
      }
    },
    MuiInput: {
      underline: {
        "&::before": {
          borderBottom: 'none'
        },
        "&:hover::before": {
          borderBottom: 'none !important'
        }
      }
    },
    MuiChip: {
      label: {
        fontFamily: "'Montserrat', sans-serif !important"
      }
    },
  }
});

const FilterIconContainer = styled(ButtonBase)({
  display: "none",
  background: "white",
  padding: 10,
  position: "absolute",
  right: 0,
  borderRadius: "8px 0 0 8px",
  boxShadow: "1px 2px 4px 0px #00000040",
  top: 100,
  "@media (max-width: 1279px)": {
    display: 'block'
  }

})

const CustomPagination = styled(Pagination)({
  display: 'flex',
  justifyContent: 'end',
  margin: "10px auto",
  "& .pagination-item": {
    fontFamily: "'Montserrat', sans-serif !important"
  }
})

const StatusBox = styled(Box)(() => ({
  padding: "5px 10px",
  borderRadius: 40,
  textAlign: 'center',
  "& p": {
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: 12,
    lineHeight: "18px"
  },
  "& .small-text": {
    display: 'none'
  },
  "@media (max-width: 1070px) and (min-width: 960px)": {
    margin: "0 auto",
    width: 'fit-content',
    "& .small-text": {
      display: 'block'
    },
    "& .big-text": {
      display: 'none'
    },
  }
}))

const CustomTableContainer = styled(TableContainer)({
  boxShadow: "1px 2px 4px 0px #00000040",
  "& .table": {
    borderCollapse: 'separate',
  },

  "& .table-body": {
    background: "white",
  },
  "@media (max-width: 820px)": {
    "& .table-cell": {
      borderLeft: "10px solid"
    }
  },
  "@media (max-width: 1070px) and (min-width: 960px)": {
    "& .table-cell": {
      borderLeft: "10px solid"
    }
  }
})

const MyAutoComplete = styled(Autocomplete)({
  border: '1px solid #CBD5E1',
  padding: 8,
  borderRadius: 8,
  minWidth: "50%",
  "& input": {
    "&::placeholder": {
      opacity: "1 !important"
    }
  }
}) as typeof Autocomplete

const CustomSelect = styled(Select)({
  border: '1px solid #CBD5E1',
  padding: 8,
  minWidth: "50%",
  borderRadius: 8,
  width: '100%',
  "& .item-select": {
    display: "flex"
  },
  "@media (max-width: 600px)": {
    width: '100%',
    minWidth: '100%'
  }
})

const SelectDateBox = styled(Box)({
  display: 'flex',
  gap: 20,
  "& .select-date-item": {
    position: 'relative',
    '& .title': {
      color: '#334155',
      fontWeight: 700,
      fontSize: 14,
      lineHeight: "22px",
      marginBottom: 5,
    },
    "& .icon-calendar": {
      // position: 'absolute',
      // right: 10,
      // transform: "translate(0, 90%)",
      color: "#64748B",
      fontSize: 18,
    }
  }
})

const ArrowUp = styled(ArrowDropUpRoundedIcon)({
  fontSize: 25,
  color: "#0F172A !important"
}) as typeof ArrowDropUpRoundedIcon

const ReportContainer = styled(Container)({
  background: "#F1F5F9",
  paddingTop: 20,
  paddingBottom: 20,
  overflowY: 'auto',
  height: '100%',
  "& .react-datepicker-popper": {
    zIndex: 20
  },
  "& .react-datepicker__day, .react-datepicker__day-name, .react-datepicker__current-month": {
    fontFamily: "'Montserrat', sans-serif !important"
  },

  "& .dateInput": {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    textDecoration: "none",
    backgroundColor: "white",
    height: "34px",
    padding: "0px 8px",
    minWidth: 140,
    width: 140,
    fontSize: 14,
    fontFamily: "'Montserrat', sans-serif !important"
  },
  "& .react-datepicker__close-icon": {
    top: '10%'
  },
  "& .react-datepicker__close-icon::after": {
    width: '18px',
    height: '22px',
    fontSize: '17px'
  },
  "@media (max-width: 1279px)": {
    "& .filter-bar": {
      display: 'none'
    },
  },
  "@media (max-width: 820px)": {
    "& .dateInput": {
      minWidth: 'auto',
      width: '100%'
    }
  }
})

const CustomButton = styled(Button)({
  padding: "10px 16px",
  borderRadius: 8,
  background: "linear-gradient(90deg, rgba(118,123,215,1) 0%, rgba(17,31,80,1) 100%)",
  color: "#FFFFFF",
  height: 'fit-content',
  marginTop: 10,
  '& .text': {
    fontWeight: 700,
    fontSize: 16,
    fontFamily: "'Montserrat', sans-serif",
    lineHeight: "24px",
    color: "#FFFFFF",

  },
  '&.button-filter': {
    background: '#34D399'
  },
  '&.button-cancel': {
    background: '#F87171'
  },
  "&:disabled": {
    opacity: 0.5,
    cursor: "not-allowed",
    color: "#FFFFFF",
  },

  "@media (max-width: 576px)": {
    "& .text": {
      fontSize: 14,
    }
  },
  "@media (max-width: 425px)": {
    "& .text": {
      fontSize: 12,
    }
  }
})

const ItemSelect = styled(MenuItem)({
  display: 'flex',
  alignItems: 'center'
})

const DropdownCheckBoxContainer = styled(Box)({
  marginBottom: 10,
  marginTop: 10,
  "& .title": {
    color: '#334155',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "22px",
    marginBottom: 5,
  }
})

const FilterBarContainer = styled(Box)({
  boxShadow: "1px 2px 4px 0px #00000040",
  background: '#FFFFFF',
  padding: 20,
  borderRadius: 8
})

// Customizable Area End
